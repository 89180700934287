#new-message-container {
  background: #778a93;
}

#new-message-container {
  display: grid;
  grid: 40px / 40px;
  align-content: center;
  grid-area: new-message-container;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0 10px;
  padding: 0 15px;
}

#new-message-container a {
  display: grid;
  place-content: center center;
  background: #eee;
  border-radius: 100%;
  color: #7a82a6;
  text-decoration: none;
  font-size: 3.6rem;
}
#new-message-container #user-list img {
  grid-row: span 2;
  height: 50px;
  width: 50px;
}
#new-message-container img {
  grid-row: span 2;
  height: 50px;
  width: 50px;
}
#new-message-container img:hover {
  z-index: 1;
  height: 70px;
  width: 70px;
}

#user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin: 0 70px 0 70px;
}
#user-list img {
  grid-row: span 2;
  height: 40px;
  width: 40px;
}
#avatar {
  border-radius: 50%;
}
#user-list #icon {
  grid-row: span 2;
  height: 25px;
  width: 25px;
}
.user-list-container {
  text-align: center;
  align-items: center;
}
#modal-label {
  padding-bottom: 20px;
}
#new-conversation-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
#new-conversation-header img {
  grid-row: span 2;
  height: 30px;
  width: 30px;
  padding-right: 5px;
}
#new-conversation-header img:hover {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  padding-right: 5px;
}
#modal-label {
  border-bottom: 1px solid rgb(46, 172, 223);
}
#new-conversation-header #close:hover {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}
