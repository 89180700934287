.card-image {
  width: 30% !important;
  max-height: 100% !important;
  object-fit: cover;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.card-style {
  background: #e9eaed;
  padding: 8px 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card_title_more_options {
  display: flex;
  justify-content: space-between;
  min-height: 33%;
}

.description {
  margin-top: 3px;
  min-height: 27%;
}

.public {
  margin-top: 10px;
  margin-right: 5px;
}
@media (min-width: 700px) and (max-width: 1024px) {
  .card-style {
    background: #e9eaed;
    padding: 20px 6px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .public {
    margin-bottom: 10px;
  }
}
