.signInHeader{
 display: flex;
 justify-content: space-between; 
 padding: 30px 10px;  
}

.signInHeader > div > *{
    display: flex;
   margin-left: 5px;
}
.signInHeader > div{
    display: flex;
}

.signInHeader > div > i{
    font-size: 1.33333rem;
    color: #9299b8;
}

.signInHeader> div > h5{
   font-size: 1.07rem;
}


.signInHeader> i{
   cursor: pointer;
 }

 .divider{
    margin-top: 10px;
    margin-bottom: 10px;
 }


 .sign-in-inputForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding:2rem;
    
 }

 .sign-in-inputForm > input{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
 }

.sign-in-form-control{
    margin-bottom: 1.33333rem;
}

.button{
margin-bottom: 1.33333rem;
}

.signInButton{
    margin-bottom: 1.33333rem;
}

.form-options{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.33333rem;
    padding: 0px 30px;
}

.form-options > .member > span{
    font-size: 0.93333rem;

}

