.typography{
    color: black;
    font-weight: bold;
}


.accordion-details:nth-child(even){
background: rgb(250, 238, 215);
padding:5px

}

.accordion-details:nth-child(odd){
    background: rgb(243, 236, 222);
    padding:5px   
}
