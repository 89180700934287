.c-player {
  display: block;
  background-image: linear-gradient(to bottom, #434343, #000000);
  /* margin: 0px auto;
  padding: 30px; */

  margin: 0px auto;
  padding: 1px;
}
.control--position {
  display: flex;
  align-items: center;
  gap: 24px;
}
.c-player > h4 {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.c-player > p {
  color: #aaa;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
}

.c-player > p span {
  font-weight: 400;
  width: fit-content;
}

.c-player--details .details-img {
  position: relative;
  /* width: fit-content; */
  width: 130px;
  height: 130px;
  margin: 45px auto;
}

.c-player--details .details-img img {
  display: block;
  margin: 50px auto;
  object-fit: contain;
  width: 100%;
  max-width: 250px;
  border-radius: 50%;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.8),
    -6px -6px 12px rgba(255, 255, 255, 0.1);
}

.c-player--details .details-img:after {
  content: "";
  display: block;
  position: absolute;
  top: -25px;
  left: -25px;
  right: -25px;
  bottom: -25px;
  border-radius: 50%;
  border: 3px dashed #97e5e5;
}

.c-player--details .details-title {
  color: #eee;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
    -2px -2px 4px rgba(255, 255, 255, 0.1);
  text-align: center;
  margin-bottom: 10px;
}

.c-player--details .details-artist {
  color: #aaa;
  font-size: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
    -2px -2px 4px rgba(255, 255, 255, 0.4);
  text-align: center;
  margin-bottom: 20px;
}

.c-player--controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.c-player--controls .play-btn {
  display: flex;
  margin: -7px 30px;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.8),
    -4px -4px 10px rgba(255, 255, 255, 0.1),
    inset -4px -4px 10px rgba(0, 0, 0, 0.4),
    inset 4px 4px 10px rgba(255, 255, 255, 0.4);
  border: none;
  outline: none;
  background-color: #49aaaa;
  color: rgb(255, 255, 255);
  font-size: 24px;
  cursor: pointer;
}

.c-player--controls .play-btn:hover {
  color: #ffffff;
  background-color: inherit;
}

.c-player--audio {
  margin-top: -20px;
  margin-bottom: 5px;
  outline-width: 0;
  opacity: 0.4;

  width: 82%;
  margin: 5px auto !important;
  display: flex;
}

.c-player--controls .skip-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: #888;
  font-size: 35px;
}
.c-player--controls .skip-btn:hover {
  transition: 0.5s ease-in-out;
  transform: scale(1.1);
  color: white;
  background-color: inherit;
}

audio::-webkit-media-controls-play-button {
  display: none;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
