#nav-notification-container li{

    border-bottom-style: solid;
    border: 0.3px solid rgb(232, 229, 229);
padding: 15px;


}

ul>span{
    margin-left: 80%;
    margin-bottom: 10%;
}

ul>span>Button{
    margin-bottom: 5px;
}

.img-notification{
    display: flex;
    
}

.la-bell{
   color:#4dd0e1;
}