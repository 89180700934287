/* .docu-modal {
  height: 2000px;
  width: 800px;
  background-color: red;
} */
.docu-iframe {
  height: calc(100vh - 10px);
  width: calc(100vw - 10px);
  box-sizing: border-box;
  /* padding: 50px; */
  overflow: auto;
}
.docusign-modal-close {
  display: flex;
  align-items: left;
  justify-content: right;
  font-size: 20px;
  cursor: pointer;
}
/* paddingRight: "20px",
                  width: "350px",
                  height: "750px", */
