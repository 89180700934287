.swipe-button-container {
  float: left;
  position: fixed;
  height: 550px;
  width: 400px;

  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -350px;
  background: #c8f1f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
  z-index: 9;
}
.swipe-button-block {
  float: left;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
}

.job-info-block {
  float: left;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  padding: 20px;
}
