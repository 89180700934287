.landing_page_review {
  display: flex;
  grid-gap: 3px;
  gap: 3px;
  margin-top: -5px;
  margin-left: 5px;
  width: auto;
}

.landing_page_review-counter {
  margin-left: 8px !important;
}

.landing_page_review-container {
  margin-top: 10px;
  display: flex;
  gap: 5px;
}
