.audio_bar {
  width: 100%;
  margin: 0 auto;
  display: grid;

  grid-auto-flow: column;

  grid-auto-columns: 30% !important;
  overflow-x: auto;
  gap: 35px;
}

.audio_bar_container {
  margin-bottom: 10px;
}

/* mobile devices */
@media only screen and (max-width: 600px) {
  .audio_bar {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 95% !important;
    overflow-x: auto;
    gap: 11px;
  }
}

/*tablet devices */
@media (min-width: 700px) and (max-width: 1024px) {
  .audio_bar {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 55% !important;
    overflow-x: auto;
    gap: 35px;
  }
}
