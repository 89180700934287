.landing_page_listing_card_save_btn {
  cursor: pointer;
  color: white;
}

.landing_page_listing_card_save_icon {
  color: white;
}

.landing_page_listing_card_save {
  display: flex;
  gap: 5px;
  margin-top: -32px;
  margin-left: 5px;
  columns: white;
}
