#chat-form {
  background: #eee;
}

/* #chat-form img {
  cursor: pointer;
} */

#chat-form {
  /* display: grid; */
  grid: 60px / 60px 60px 1fr;
  align-content: center;
  align-items: center;
  grid-gap: 500px;
  grid-area: chat-form;
  border-radius: 0 0 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-left: 42px;
  padding-right: 0px;
}

#chat-form input[type="text"] {
  outline: none;
  padding: 15px;
  border: 2px solid #ddd;
  color: #330;
  border-radius: 6px;
  font-size: 1.4rem;
  /* width:500px */
}


#chat-form img {
  width: 30px;
  height: 30px;
  margin-top: 24px;
  margin-left: 4px;
}

.form img:hover {
  transform: scale(1.2)
}

.no_pad {
  padding: 0px !important;
}

/* .hide {
  display: none;
} */

.upload_file,
.upload_pic,
.upload_audio,
.upload_video {
  position: relative;
}

.documentFile {
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 0;
    left: 3px;
    opacity: 0;
    pointer-events: all;
    cursor: pointer;
    height: 26px;
    width: 28px;
}

.photoFile {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 5px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  height: 24px;
  width: 25px;
}

.audioFile {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 9px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  height: 24px;
  width: 17px;
}

.videoFile {
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  left: 4px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  height: 27px;
  width: 28px;
}

.addFile {
  position: relative;
}

.inputIcn {
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  left: 9px;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
  /* height: 33px; */
  width: 36px;
}

.icnBtn {
  padding: 1px !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addSize {
  font-size: 3.5rem !important;
}

.sendMultiple {
  width: 30px;
  height: 30px; 
}

@media only screen and (max-width: 768px) {
  #chat-form input[type="text"] {
    outline: none;
    padding: 5px;
    border: 2px solid #ddd;
    color: #330;
    border-radius: 6px;
    font-size: 1rem;
    /* width: 300px */
  }

  #chat-form {
    /* display: grid; */
    grid: 50px / 52px 1fr;
    align-content: center;
    align-items: center;
    grid-gap: 270px;
    grid-area: chat-form;
    border-radius: 0 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 12px;
    padding-right: 0px;


  }

  #chat-form img {
    width: 30px;
    height: 30px;
  }



}