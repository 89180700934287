.form-container {
  width: 0%;
  margin-top: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.form-title {
  font-size: 36px;
  margin-bottom: 2.5rem;
  font-weight: 500;
  opacity: 0.8;
}

.checkout-form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.input-line {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
}

label {
  font-size: 16px;
  color: grey;
  margin-bottom: 0.5rem;
}

.payment-input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: none;
  font-size: 18px;
}

.payment-input::placeholder {
  font-size: 14px;
  color: #b9b9b9;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-container .input-line {
  width: 45;
}

input[type="button"] {
  background-color: rgb(0, 211, 211);
  color: white;
  font-weight: 500;
  font-size: 18px;
  height: 50px;
  padding: 0 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="button"]:hover {
  background-color: rgb(42, 138, 138);
}

.cart {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  width: 100%;

  margin: auto;
  padding-left: 290px;
}

.payment {
  display: flex;
  justify-content: space-between;
}

.payment-info > div {
  display: flex;
  flex-direction: row;
}

.payment-info > div > p {
  font-size: 25px;
  margin-bottom: 30px;
}

.payment-info {
  margin-top: 20px;
}

.payment {
  display: flex;
  justify-content: space-between;
}

.payment-container {
}

.payment-info-value {
  margin-top: 20px;
}

.payment-info-value > div > p {
  margin-left: 20px;
  font-size: 25px;
  margin-bottom: 30px;
}

.cart-card {
  display: flex;
  justify-content: space-evenly;
  border: 1px rgb(206, 204, 204) solid;
  align-items: center;
  padding-left: 10px;
}

.left-img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}

.cart-card-img {
  width: 10%;
  height: 45%;
}

.cart-card-info {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-top: 20px;
}

.cart-card-info > div {
  display: flex;
}

.cart-card-info > div > .info {
  margin-left: 5px;
}

.info-value {
  padding-bottom: 7px;
}

.card-cart-title {
  display: flex;
  flex-direction: column;
}

.card-cart-value {
  display: flex;
  flex-direction: column;
}

.cart-card-detail {
  display: flex;
  justify-content: flex-start;
}

.course {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 700px;
}

.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
}

.course-preview {
  background-color: #97e5e5;
  color: #fff;
  padding: 0px;
  width: 20%;
}

.course-preview a {
  color: #fff;
  display: inline-block;
  font-size: 12px;
  opacity: 0.6;
  margin-top: 30px;
  text-decoration: none;
}

.course-info {
  padding: 30px;
  position: relative;
  width: 100%;
}

.course-info > div {
  display: flex;
}
.card-data {
  color: black !important;
}

.card-btn {
  background-color: #3fcfcf;
  border: 0;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  padding: 12px 25px;
  position: absolute;
  bottom: 10px;
  right: 20px;
  letter-spacing: 1px;
}

.pending-project {
  display: flex;
  /* width: 33%; */
  flex-wrap: wrap;
}

.cart-cards {
  display: flex;
}

@media screen and (max-width: 480px) {
  .pending-project {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .courses-container {
    width: 100%;
  }

  .course-preview {
    width: 100%;
  }

  .card-btn {
    bottom: 0px;
    margin-bottom: 5px;
    padding: 6px 8px;
    font-size: 8px;
  }

  .course-info > div {
    display: flex;
    flex-direction: column;
  }

  .course h6 {
    margin-bottom: 0px;
  }

  .course-info {
    padding: 10px;
  }
  .cart-cards > * {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
