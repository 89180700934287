.media-scroller{
    display: grid;
    gap:2rem;
    grid-auto-flow: column;
    grid-auto-columns: 10%;

    overflow-x: auto;
    overscroll-behavior-inline: contain;
}

.media-element{
    padding:3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}




.media-element > img:hover{
    transition: transform .5s ease;
    transform: scale(1.08);
}

.big-image-and-info{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.big-image{
    max-width: 40%;
    max-height: 30%;
    margin: auto;
    object-fit: cover;
}

.media-element{
display: flex;

}