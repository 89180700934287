.landing_page_listing_card_main_card {
  outline: none !important;
}

.landing_page_listing_card_card-image {
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset !important;
  z-index: 1;
}

.landing_page_listing_card_avatar {
  position: relative;
  margin-top: -45px;
  border: 8px solid #ffffff;
  margin-left: 210px;
}

.landing_page_listing_card_badges {
  /* position: relative;
  margin-top: -275px;
  margin-left: 10px; */
  position: absolute;
  margin-top: -138px;
  margin-left: 6px;
  gap: 6px;
  display: flex;
}

.landing_page_listing_card_bottom_btns {
  display: flex;
  justify-content: space-between;
}

.landing_page_listing_card_industries_btn {
  font-weight: bold !important;
}

.landing_page_listing_card_viewmore_btn {
  color: rgb(57 57 57) !important;
  font-weight: bold !important;
}

.landing_page_listing_card_industries_count_container {
  display: flex;
  gap: 7px;
}

.landing_page_listing_card_username {
  line-height: 0.2rem !important;
}

.landing_page_listing_card_description {
  line-height: 0.23rem !important;
}
