.personal {
  position: fixed;
  top: 0;
  margin-top: 85px;
  max-width: 130px;
}

.author_name {
  font-weight: bolder !important;
}

.time_display {
  color: grey;
}
.view_count {
  width: 200% !important;
}
.discovery_user_avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.comment_card {
  width: auto;
}
.date {
  margin-left: 3px;
}
.more_comments {
  padding-left: 25px;
  cursor: pointer;
  font-weight: 400;
}

.more_replies {
  cursor: pointer;
  font-weight: 400;
}

.img_style {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.title_closebtn {
  display: flex;
  justify-content: space-between;
}
.commentButton {
  margin-left: 50px !important;
  margin-top: 10px !important;
  outline: none !important;
}

.header_uploader {
  display: flex;
}
.new_post_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  height: 50px;
  border-radius: 100px;
  margin: auto;
  width: 8%;
  position: fixed;
  left: 100;
  z-index: 1;
  margin-left: 268px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.new_post_notification > * {
  font-weight: bold;
}

.bottom_nav {
  outline: none !important;
}
.user_contents {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
}

.content {
  display: flex;

  margin-right: 10px;
}
.content_upload {
  border: none !important;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}
.upload_modal {
  border: none !important;
  border-radius: 15px;
  max-height: 550px;
  min-width: 521.25px;
  overflow: auto;
}
.content > p {
  font-size: 18px;
  margin-right: 5px;
}

.content > .fa-camera {
  font-size: 18px;
  margin-top: 5px;
}

.content > .fa-film {
  font-size: 18px;
  margin-top: 5px;
}

.content > .fa-file-audio {
  font-size: 18px;
  margin-top: 5px;
}

.post-content {
  margin-bottom: 30px !important;
  margin-top: 85px !important;
}

.post_card {
  margin-bottom: 13px !important;
}

.post_icon {
  color: #4dd0e1;
}

.icon_btn {
  display: flex;
  flex-direction: column;
  outline: none !important;
}

.icon_btn > span {
  font-size: 12px;
}

.top-post-card {
  width: 70%;

  margin-top: "30px";
}

.trending {
  margin-bottom: 20px;
}

.new_listing {
  margin-bottom: 20px;
}
.advert {
  outline: none !important;
}

.personal {
  display: flex;
  flex-direction: column;
}

.btn {
  margin-bottom: 10px;
}

.commentTextField {
  width: 100%;
}
.discover_post_feed__post_icons {
  visibility: hidden !important;
}
.commentInput {
  background-color: #f5f7f9;
  margin: 0 0;
  height: 40px;
  padding: 15px 25px;
  width: 85%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 20px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.input-group {
  display: flex;
  flex-direction: row;
}

.btn {
  margin-bottom: 10px;
}

.commentTextField {
  width: 100%;
}

.removeBorderLine {
  outline: none !important;
}

.commentInput {
  background-color: #f5f7f9;
  margin: 0 0;
  height: 40px;
  padding: 15px 25px;
  width: 85%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 20px;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.input-group {
  display: flex;
  flex-direction: row;
}

.avatar {
  margin-right: 10px;
}

.comment {
  display: flex;
}

.commentAvatar {
  margin-left: 0.8rem;
}

.comment-body {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.replyIcon {
  margin-right: 10px;
}

.reply {
  display: flex;
  margin-bottom: 10px;
  margin-left: 25px;
  cursor: pointer;
}

.projects {
  display: flex;
  justify-content: space-between;

  border-radius: 5px;
}

.listed {
  padding: 4px;
  background-color: wheat;
  border-radius: 5px;
}

.paid {
  padding: 4px;
  background-color: rgb(129, 111, 79);
  color: white;
  border-radius: 5px;
}

.navigation {
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  margin-top: 400px;
  max-width: 130px;
}

.username {
  color: rgb(56, 195, 255);
}

.route:hover {
  color: blue;
  cursor: pointer;
}

.routediv {
  cursor: pointer;
  display: flex;
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.see_more {
  color: rgb(0, 88, 188);
}
.tags > span {
  color: rgb(0, 88, 188);
  margin-right: 5px;
  font-weight: 200;
}

.post-input {
  display: flex;
}

#post-input-field {
  width: 450px;
  border: 0px solid white !important;
  border-radius: 20px;
}

.icon {
  color: #545353;
}

.postedOn_viewMore {
  display: flex;
  justify-content: flex-start;
  color: rgb(80, 80, 80);
}

.postedOn_viewMore > * {
  font-size: 0.9rem;
}

.viewMore {
  margin-left: 10px;
  color: #4dd0e1;
}

.listing_title {
  font-weight: 700;
}
.post_feed_container {
  display: flex;
  gap: 1.5rem;
}

.personal_navigation {
  flex-basis: 20%;
}

.postcontent_postcard {
  flex-basis: 45%;
  max-width: 655px;
}

.trending_advertisement {
  margin-top: 85px;
  flex-basis: 25%;
  position: fixed;
  left: 67%;
}

.reply_section {
  width: 92%;
  margin: 0 auto;
}

.main_container {
  margin-left: 60px;
  margin-right: 60px;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.bottom_nav_bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-bottom: -8px !important;
  background-color: #cfcdcd !important;
}

.report_btn {
  margin-top: 10px !important;
}

.grid-ish {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  overflow: auto;
}

.grid-ish > * {
  flex: 1 1 48%;
}

.avoid_outline {
  outline: none !important;
}

video {
  transform: scale(1) !important;
}

#upload_input {
  width: 100%;
  height: 40px !important;
  border: 2px solid #aaa;
  border-radius: 6px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

#upload_input:focus {
  border-color: grey;
  box-shadow: 0 0 2px 0 dodgerBlue;
}

.videoBox {
  display: flex;
  flex-direction: column;
  gap: 10;
  margin-top: 10px;
}

.reverse-reply {
  display: flex;
  flex-direction: column-reverse;
}

.posting_spinner {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.post_options_report_btn {
  color: black;
}

/* Mobile Devices  range 270px - 389px */
@media screen and (min-width: 270px) and (max-width: 389px) {
  .post_feed_content_uploader_audio {
    display: none !important;
  }

  .upload_modal {
    border: none !important;
    border-radius: 15px;
    max-height: 550px;
    min-width: 411.35px !important;
    max-width: 411.35px !important;
    overflow: auto;
  }
  .postcontent_postcard {
    margin: auto;
    width: 95%;
  }
  .reply_card {
    min-width: 256px !important;
  }
  .post-content {
    width: 100%;
  }
  .skeleton {
    width: 10% !important;
  }
  .postcontent_postcard {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  .reply_section {
    width: 92%;
    margin: 0 auto;
  }

  .commentInput {
    width: 79% !important;
    background-color: #f5f7f9;
  }

  .main_container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .personal_navigation {
    display: none;
  }
  .trending_advertisement {
    display: none;
  }

  @media screen and (max-width: 320px) {
    .post_feed_content_uploader_audio {
      display: none !important;
    }
    .reply_section {
      width: 92%;
      margin: 0 auto;
    }
    .upload_modal {
      border: none !important;
      border-radius: 15px;
      max-height: 550px;
      min-width: 350.35px !important;
      max-width: 350.35px !important;
      overflow: auto;
    }
  }
}

/* Tablet and big screen devices range 390px - 512px */
@media screen and (min-width: 390px) and (max-width: 512px) {
  .post_feed_content_uploader_audio {
    display: none !important;
  }
  .upload_modal {
    border: none !important;
    border-radius: 15px;
    max-height: 550px;
    min-width: 411.35px !important;
    max-width: 411.35px !important;
    overflow: auto;
  }
  .postcontent_postcard {
    margin: auto;
    width: 95%;
  }
  .reply_card {
    min-width: 256px !important;
  }
  .post-content {
    width: 100%;
  }

  .postcontent_postcard {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  .reply_section {
    width: 92%;
    margin: 0 auto;
  }

  .commentInput {
    width: 79% !important;
    background-color: #f5f7f9;
  }

  .main_container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .personal_navigation {
    display: none;
  }
  .trending_advertisement {
    display: none;
  }
}

/* extra large devices  range 540px - 1280px */
@media only screen and (min-width: 540px) and (max-width: 1280px) {
  .post_feed_content_uploader_audio {
    display: none !important;
  }
  .upload_modal {
    border: none !important;
    border-radius: 15px;
    max-height: 550px;
    min-width: 411.35px !important;
    max-width: 411.35px !important;
    overflow: auto;
  }
  .postcontent_postcard {
    margin: auto;
    width: 95%;
  }
  .post-content {
    width: 100%;
  }
  .reply_section {
    width: 92%;
    margin: 0 auto;
  }
  .postcontent_postcard {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  .commentInput {
    width: 79% !important;
    background-color: #f5f7f9;
  }
  .reply_card {
    min-width: 546px !important;
  }
  .main_container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .personal_navigation {
    display: none;
  }
  .trending_advertisement {
    display: none;
  }
  .search_addlisting {
    display: none;
  }

  .bottom_nav_bar {
    display: inline !important;
  }
}

/* @media only screen and (min-width: 1024px) {
  .trending_advertisement {
    width: 26% !important;
  }
} */

@media only screen and (max-width: 1920px) {
  .trending_advertisement {
    position: fixed;
    left: 67%;

    margin-top: 88px;

    width: auto !important;
  }
}
