.sliderStyles {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.rightArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 32px;
  font-size: 20px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.leftArrowStyles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 32px;
  font-size: 20px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
}

.slideStyles {
  position: relative;
  height: 100%;
}

.dotsContainerStyles {
  display: flex;
  justify-content: center;
}

.dotStyle {
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;
}

.containerStyles {
  width: 100%;
  height: 280px;
  margin: 0 auto;
}

@media only screen and (max-device-width: 480px) {
  .containerStyles {
    width: 100%;
    height: 280px;
    padding-right: 5px;
    margin-left: 2px;
  }
}
