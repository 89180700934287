#chat-title {
  display: grid;
  grid: 36px / 1fr 36px;
  align-content: center;
  align-items: center;
  grid-area: chat-title;
  background: #eee;
  color: #7a82a6;
  font-weight: bold;
  font-size: 2rem;
  border-radius: 0 10px 0 0;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  padding: 0 20px;
}
.new-conversation-modal {
  position: fixed;
  width: 300px;
  height: 400px;
  z-index: 1040;
  overflow: auto;
  top: 20%;
  left: 10%;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
}
#chat-title > img {
  cursor: pointer;
  height: 30px;
  width: 30px;
  transition: transform 0.4s;
}
#chat-title > img:hover {
  cursor: pointer;
  width: 33px;
  height: 33px;
  transform: scale(1.1);
}
.popper-menu {
  border: 1px solid;
  background-color: #7a82a6;
  color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popper-conversation {
  border: 1px solid;
  background-color: #7a82a6;
  color: white;
  padding: 10px;
  height: 115vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popper-menu li {
  list-style: none;
}
.popper-menu button {
  margin-bottom: 10px;
  border: none;
  border-radius: 5%;
  background: none;
  color: white;
  font-size: larger;
  font-weight: 700;
  outline: none;
  transition: transform 0.4s;
}
.popper-menu i {
  padding-right: 20px;
}
.popper-menu button:hover {
  margin-bottom: 10px;
  border: none;
  border-radius: 5%;
  background: none;
  color: white;
  font-size: larger;
  font-weight: 700;
  outline: none;
  transform: scale(1.1);
}
@media only screen and (max-width: 768px) {
  #chat-title > img {
    cursor: pointer;
    height: 20px;
    width: 20px;
    transition: transform 0.4s;
  }
  #chat-title {
    display: grid;
    grid: 36px / 1fr 25px;
    align-content: center;
    align-items: center;
    grid-area: chat-title;
    background: #eee;
    color: #7a82a6;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    padding: 0 20px;
    width: 87%;
    margin: 0px auto;
  }
  #chat-title > img:hover {
    cursor: pointer;
    height: 20px;
    width: 20px;
    transform: scale(1.1);
  }
  /* Popper New Conversation */
  #popper-message-container img {
    grid-row: span 2;
    height: 50px;
    width: 50px;
  }
  #popper-message-container {
    background: #778a93;
  }

  #popper-message-container {
    display: grid;
    grid: 40px / 40px;
    align-content: center;
    grid-area: new-message-container;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0 10px;
    padding: 17px 15px;
  }

  #popper-message-container a {
    display: grid;
    place-content: center center;
    background: #eee;
    border-radius: 100%;
    color: #7a82a6;
    text-decoration: none;
    font-size: 3.6rem;
  }

  #popper-message-container #user-list img {
    grid-row: span 2;
    height: 50px;
    width: 50px;
  }

  #popper-message-container img {
    grid-row: span 2;
    height: 50px;
    width: 50px;
  }

  #popper-message-container img:hover {
    z-index: 1;
    height: 70px;
    width: 70px;
  }

  #user-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin: 0 10px 0 10px;
  }

  #user-list img {
    height: 30px;
    width: 30px;
  }

  #avatar {
    border-radius: 50%;
  }

  #user-list #icon {
    grid-row: span 2;
    height: 25px;
    width: 25px;
  }

  .user-list-container {
    text-align: center;
    align-items: center;
  }

  #modal-label {
    padding-bottom: 20px;
  }

  #new-conversation-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  #new-conversation-header img {
    grid-row: span 2;
    height: 50px;
    width: 50px;
    padding-right: 5px;
  }

  #modal-label {
    border-bottom: 1px solid rgb(46, 172, 223);
  }

  #new-conversation-header #close:hover {
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
}
