.dropdown {
  display: flex;
}
.tablet {
  display: none !important;
}
.content-btn {
  color: #97e5e5 !important;
}

.multiple-select {
  margin-top: 10px;
  border-right: none;
  border-radius: 0px;
}

.textfield {
  border-radius: 0px;
}
.search_box {
  margin-left: 495px !important;
}

.box {
  min-width: 600px;
}
#searchInput {
  position: relative;
  display: inline-block;
  font-size: 20px;
  box-sizing: border-box;
  border-right: none;
  left: -3px;
  border-radius: 0px !important;
}
.addlisting_box {
  margin-top: 10px;
}
#searchInput {
  margin-top: 10px;
  background: #ecf8fb;
  width: 340px;
  height: 32px;
  border: none;
  outline: none;
  padding: 0 25px;
}
select {
  position: relative;
  left: -3px;
  top: -2px;
  height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 11px 16px 12px;
  font-size: 18px;
  margin-left: -2px;
  margin-right: -2px;
  border-right: 15px solid #fff;
}
.search {
  position: relative;
  left: -5px;
  width: 50px;
  height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  background: #4dd0e1;
  color: #fff;
  font-size: 30px;
  padding-left: 10px;
  margin-left: 5px;
}
input[type="submit"]:hover {
  background: #0097a7;
}

.formcontainer {
  display: flex;
}

.content-icon {
  font-size: 9px;
  padding: 8px;
  color: white;
  border-radius: 50%;
}

.content-container {
  margin-bottom: 15px;
}

.content-button {
  margin-top: 10px !important;
  background-color: #97e5e5 !important;
  width: 90px;
  outline: none !important;
}

.popover {
  padding: 10px;
}
.popper {
  margin-top: 10px;
  background-color: white !important;
}

.popover-container {
  padding: 10px;
  margin-top: 10px;
}

.content-icon > span {
  margin-left: 10px;
}

#searchIcon {
  color: #999393 !important;
  position: relative;
  right: 40px;
  top: 7px;
  font-size: 30px;
  cursor: pointer;
}

input,
input::placeholder {
  font-size: 18px;
}

/* Mobile Devices  range 270px - 389px */
@media screen and (min-width: 270px) and (max-width: 389px) {
  #searchInput {
    width: 283px !important;
  }

  .box {
    width: 90%;
  }
  .dropdown_btn {
    margin-left: 7px !important;
  }
  .content_popover {
    /* margin-left: 30px;
    margin-right: 30px; */
  }
  .dropdown_btn {
    /* margin-left: 30px; */
  }
  @media screen and (max-width: 320px) {
    #searchInput {
      width: 239px !important;
    }
  }
}

/* Tablet and big screen devices range 390px - 512px */
@media screen and (min-width: 390px) and (max-width: 512px) {
  .dropdown_btn {
    margin-left: 20px !important;
  }
  #searchInput {
    width: 300px !important;
  }
  .box {
    width: 100%;
  }
  .content_popover {
    margin-left: 20px;
  }
  .dropdown_btn {
    margin-left: 5px;
  }
}
/* tablet and big screen */
@media only screen and (min-width: 540px) and (max-width: 1280px) {
  .addlisting_box {
    display: none !important;
  }
  .dropdown {
    display: none;
  }
  .tablet {
    display: inline !important;
  }
  .search_inputs {
    display: flex;
    justify-content: center;
  }

  .dropdown_btn {
    margin-left: 80px !important;
  }
  #searchInput {
    width: 500px !important;
  }
}

@media only screen and (min-width: 1024px) {
  /* .search_box {
    display: none !important;
  }
  .addlisting_box {
    display: none !important;
  } */
  .box {
    min-width: 200px !important;
  }
  .search_box {
    /* margin-left: 500px !important; */
    max-width: 700px !important ;
  }
  #searchInput {
    width: 335px !important;
  }
}
