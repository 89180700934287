#video-call-notification-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#answer {
  color: green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

#decline {
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
#video-call-notification-container {
  display: block;
  text-align: center;
}
#video-caller-info {
  padding-top: 20px;
}

#answer i {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.9s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}
#decline i {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.9s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
