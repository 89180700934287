.modal__welcome_container_box {
  width: 50% !important;
}

.modal__welcome_lists > li {
  display: flex;
  justify-content: flex-start;
  line-height: 3;
  font-size: 16px;
}

/* .modal__welcome_lists > li::before {
  content: "\2022";
  font-size: 15px;
  margin-right: 10px;
} */
/* .modal__welcome_lists > li:last-child {
  display: flex;
  justify-content: center;
  line-height: 3;
  font-size: 19px;
  font-weight: 600;
} */
.modal__welcome_bottom_title {
  display: flex;
  justify-content: center;
  text-align: center;
}
.modal__welcome_title {
  margin-left: 20%;
}

@media only screen and (max-width: 600px) {
  .modal__welcome_container_box {
    width: 99% !important;
  }
  .modal__welcome_lists > li {
    display: flex;
    justify-content: flex-start;
    line-height: 2;
    list-style-type: circle;
  }
}
