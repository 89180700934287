.footer__container-wrapper {
  background-color: #9a9b9c;
  padding: 35px 0;
  margin-top: 20px;
}

.footer__bottom_logo {
  width: 8%;
  margin: 10px auto;
}

.footer__container {
  max-width: 1170px;
  margin: auto;
}

.footer_divider {
  margin: auto !important;
  background-color: #c3c3c3;
  width: 97%;
}

ul {
  list-style: none;
  padding-left: 0px;
}

.footer__row {
  display: flex;
  flex-wrap: wrap;
}

.footer__col {
  width: 25%;
  padding: 0 15px;
}

.footer__col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 500;
  position: relative;
}

.footer__col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #0c90f7;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer__col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer__col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: rgb(20, 20, 20);
  display: block;
  transition: all 0.3s ease;
}

.footer__col ul li a:hover {
  color: black;
  padding-left: 8px;
}

.footer__bottom {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.footer__social_links {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 15px;
}
.footer__bottom > * {
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
}

/*responsive*/
@media (max-width: 767px) {
  .footer__col {
    width: 25%;
    margin-bottom: 30px;
  }
}

@media (max-width: 574px) {
  .footer__col {
    width: 100%;
  }
}
