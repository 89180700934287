.MuiCardContent-root {
  padding: 7px !important;
}

.info-card {
  background: #c9c9c966 !important;
  height: 20% !important;
  outline: none !important;
}

.card-content {
  width: 100%;
}

.card-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px !important;
}

.title-and-tag {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  align-items: start;
  margin-top: 3px;
}

.delete-btn {
  outline: none !important;
}
.update-btn {
  margin-top: 10px;
  /* background: rgb(252, 157, 157); */
  outline: none !important;
}

.delete-btn {
  color: rgb(192, 38, 38);
}
