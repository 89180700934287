#conversation-list {
  grid-area: conversation-list;
  background: #778a93;
  overflow-y: auto;
}

.conversation {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  grid-gap: 10px;
  color: #ddd;
  font-size: 1.3rem;
  border-bottom: 1px solid black;
  padding: 20px 20px 20px 15px;
}

.conversation.active,
.conversation:hover {
  background: rgb(53 210 210);
}

.conversation:hover {
  cursor: pointer;
}

.conversation > img {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border: 2px solid red;

  border-radius: 100%;
}

.title-text {
  font-weight: bold;
  color: #eee;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}

.created-date {
  color: #ddd;
  white-space: nowrap;
  font-size: 1rem;
}

.conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
}
