.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 280px;
}

.progress-done {
  background: linear-gradient(to left, #33cccc, #33cccc);
  box-shadow: 0 3px 3px -5px #33cccc, 0 2px 5px #33cccc;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
