.chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 20px;
  overflow: auto;
}

.message-row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 20px;
}

.message-content {
  display: grid;
}

.you-message {
  justify-content: end;
}

.you-message .message-content {
  justify-items: end;
}

.other-message {
  justify-items: start;
}

.other-message .message-content {
  grid-template-columns: 48px 1fr;
  grid-column-gap: 15px;
}

.message-row img {
  /* border-radius: 100%;
  grid-row: span 2; */
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.message-time {
  font-size: 0.9rem;
  color: #777;
}

.message-text {
  padding: 9px 14px;
  font-size: 1rem;
  margin-bottom: 5px;
}

.you-message .message-text {
  background: #778a93;
  color: #eee;
  border: 1px solid rgb(53 210 210);
  border-radius: 14px 14px 0 14px;
}

.other-message .message-text {
  background: #eee;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}

#socibot-applied-listing-container {
  /* display: flex;
flex-direction: column;

justify-content: center;
align-items: center; */
  width: 350px;
}

#socibot-applicant-accept {
  color: #fff;
  font-weight: 700;
  border: 0 none;
  /* line-height: 3.33333rem; */
  /* z-index: -1; */
  background-color: green;
  border-radius: 14px;
  font-size: 10px;
  padding: 5px;
  line-height: 20px;
  width: 20%;
  align-self: center;
}
#socibot-button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

#socibot-applicant-decline {
  color: #fff;
  font-weight: 700;
  border: 0 none;
  /* line-height: 3.33333rem; */
  /* z-index: -1; */
  background-color: green;
  border-radius: 14px;
  font-size: 10px;
  padding: 5px;
  line-height: 20px;
  width: 20%;
  align-self: center;
}

#socibot-applicant-profile {
  color: #fff;
  font-weight: 700;
  border: 0 none;
  /* line-height: 3.33333rem; */
  /* z-index: -1; */
  background-color: green;
  border-radius: 14px;
  font-size: 10px;
  padding: 5px;
  line-height: 20px;
  width: 40%;
  align-self: center;
  display: flex;
  justify-content: center;
}
#socibot-applicant-closed {
  border: 1px solid rgb(53 210 210);
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 1);
  padding: 2px;
  text-align: center;
  font-weight: bold;
  color: green;
}
.chatImg {
  height: 150px !important;
  width: 200px !important;
  border-radius: 0% !important;
  margin-top: 20px;
}
.chatVideo {
  height: 150px !important;
  width: 200px !important;
  margin-top: 20px;
}
.dcmntFile {
  margin-top: 20px;
}
.message-text-attachment {
    border-radius: 0 0 14px 14px !important;
}
video {
  box-shadow: none !important;
}

.audioFileL {
  width: 235px !important;
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .chat-message-list {
    grid-area: chat-message-list;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5px;
    overflow-y: auto;
    padding: 0px 50px;
  }
  .message-text {
    padding: 9px 14px;
    font-size: 0.8em;
    margin-bottom: 5px;
  }
  .other-message .message-content {
    grid-template-columns: 25px 1fr;
    grid-column-gap: 15px;
  }
  .message-row {
    display: grid;
    grid-template-columns: 95%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #socibot-applied-listing-container {
    width: 100%;
  }

  #socibot-applicant-accept {
    color: #fff;
    font-weight: 700;
    border: 0 none;
    /* line-height: 3.33333rem; */
    /* z-index: -1; */
    background-color: green;
    border-radius: 14px;
    font-size: 10px;
    padding: 5px;
    line-height: 20px;
    width: 50%;
    align-self: center;
  }

  #socibot-button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  #socibot-applicant-decline {
    color: #fff;
    font-weight: 700;
    border: 0 none;
    /* line-height: 3.33333rem; */
    /* z-index: -1; */
    background-color: green;
    border-radius: 14px;
    font-size: 10px;
    padding: 5px;
    line-height: 20px;
    width: 50%;
    align-self: center;
  }

  #socibot-applicant-profile {
    color: #fff;
    font-weight: 700;
    border: 0 none;
    /* line-height: 3.33333rem; */
    /* z-index: -1; */
    background-color: green;
    border-radius: 14px;
    font-size: 10px;
    padding: 5px;
    line-height: 20px;
    width: 40%;
    align-self: center;
    display: flex;
    justify-content: center;
  }
}
