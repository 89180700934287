#chat-container {
  display: grid;
  grid:
    "search-container chat-title" 71px
    "conversation-list chat-message-list" 1fr
    "new-message-container chat-form" 78px
    / 300px 1fr;
  min-width: 1000px;
  max-width: 1000px;
  max-height: 800px;
  width: 100%;
  height: 80vh;
  background: #fff;
  border-radius: 10px;
  margin: auto;
  padding-top: 0px;
  justify-content: center;
}
#online-users {
  display: none;
}
#title-options-bar {
  display: none;
}
@media only screen and (max-width: 768px) {
  /******** Chat Container *****************/
  #chat-container {
    display: grid;
    grid:
      "online-users" 100px
      "chat-title" 40px
      "chat-message-list" 1fr
      "chat-form" 40px
      / 380px;
    min-width: 100%;
    max-width: 100%;
    max-height: 800px;
    width: 100%;
    height: 80vh;
    background: #fff;
    border-radius: 10px;
    margin: auto;

    justify-content: center;
  }
  /*********************** END **************************/
  /* #chat-input{
  width: 100%;
} */
  #chat-message-list {
    border-radius: 0 10px 0 0;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  }

  #title-options {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  #new-message-container {
    display: none;
  }
  #conversation-list {
    display: none;
  }
  #search-container {
    display: none;
  }
  #title-options-bar {
    display: block;
  }
  /******* Online Users Horizontal List Header for Mobile  */
  #online-users {
    display: grid;
    grid: 36px / 1fr 0px;
    align-content: center;
    align-items: center;
    grid-area: online-users;
    background: #eee;
    color: #7a82a6;
    font-weight: bold;
    font-size: 1rem;

    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    padding: 5px;
    width: 87%;
    margin: 0px auto;
  }

  #list-online-users {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 20px;
    justify-items: center;
  }

  #online-users section {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
  /**************************************   END   *****************************/
}
