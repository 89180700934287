.project_info {
  display: flex;
  margin-bottom: 10px;
}
.project_info > h4 {
  color: #4dd3d3;
}

.project_info > h5 {
  font-size: 18px;
  margin-left: 5px;
  color: black;
}

.job_description {
  text-align: justify;
  color: black;
  line-height: 1.6;
}

.atbd_area_project-infos {
  color: #4dd3d3;
}

.atbd_area_project-infos_value {
  color: black;
  font-weight: bold;
}
