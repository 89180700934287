.landing_page_jumbotron {
  background-image: url("./jumbotron.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  height: 400px;
}

.landing_page_search_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing_page__navbar {
  width: 100% !important;
  margin-bottom: 10px;
}

.landing_page_jumbotron_title_description_container {
  margin-top: 45px;
  display: flex;
  align-items: center;
}

.landing_page_jumbotron_title_description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 58px;
  width: 70%;
  margin: 0px auto;
  text-align: center;
}

.landing_page_jumbotron_bottom_description {
  margin-top: 2.5%;
}

.landing_page_search_container {
  width: 40%;
}

.landing_page_jumbotron_description {
  width: 61%;
  color: white;
  text-align: center;
  margin-top: 2%;
  font-size: 23px;
}

.landing_page_jumbotron_title {
  font-size: 35px;
  font-weight: 900;
  color: white;
}

.landing_page_jumbotron_input_group_text {
  background-color: #33cccc;
  color: white;
  height: 48px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.landing_page_jumbotron_search_icon {
  color: white !important;
}

@media only screen and (max-width: 600px) {
  .landing_page_jumbotron {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background-image: url("./jumbotron.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .landing_page_search_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing_page_jumbotron_title {
    font-size: 21px;
    text-align: center;
  }

  .landing_page_jumbotron_title_description {
    width: 95%;
  }

  .landing_page_jumbotron_description {
    width: 95%;
    text-align: center;
    font-size: 18px;
  }

  .landing_page_jumbotron_title_description_container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .landing_page_jumbotron_input_group_text {
    height: 41px;
  }
}