.landing_page_listing_card_count {
  display: flex;
  flex-direction: center;
  align-items: center;
  margin: 1px 2px;
  font-size: 11px;
}

.landing_page_listing_card_industries_count {
  border: 2px solid grey;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  margin-top: 3px;
}

.landing_page_listing_card_popper {
  padding: 0px 50px;
}
