.logo-size {
  width: 40px;
  height: 11px;
}

.user_avatar {
  outline: none !important;
}
.notification_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-size > img {
  margin-top: -20px;
}

.user-name {
  color: black;
}

.navbar-container {
  background-color: #f9feff;
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.avatar-box {
  display: flex;
}

.avatar-box > p {
  margin-right: 1rem;
  margin-top: 10px;
  font-size: 15px;
  margin-left: 20px;
}

.notification {
  margin-top: 5px;
  margin-left: 10px;
}

.nav_button {
  margin-right: 10px;
}

.app-bar {
  border: none !important;
}

.search {
  max-width: 50px;
  min-width: 50px;
}

.tool-bar {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .notification_clear_button {
    width: 33% !important;
  }
}
