@media only screen and (max-width: 490px) {
  .payed_projects_prepare_documents {
    margin-left: 20px !important;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
    max-width: 278px !important;
    min-width: 273px !important;

    border-radius: 5px !important;
    padding: 10px !important;
  }
}

.project_activity_payed_projects_alert {
  width: 80%;
}
