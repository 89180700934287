.profileImgCard {
  position: absolute;
  z-index: 1;
}

.avatarAndUserName {
  display: flex;
}
.profile_card_avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.avatar {
  margin-right: 0.8rem;
}

.reply {
  margin-top: 10px;
}

.projects {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.listed {
  padding: 4px;
  background-color: wheat;
  margin: auto 0;
}

.paid {
  padding: 4px;
  background-color: rgb(129, 111, 79);
  color: white;
}

.route {
  margin-bottom: 3px;
}
