.scroll {
  display: grid;
  gap: 20px;
  grid-auto-flow: column;
  grid-auto-columns: 30%;

  /* 50% */

  overflow-x: auto;
  padding-bottom: 10px;
  overscroll-behavior-inline: contain;
}

/* .card-image {
    width: 40%;
  } */
.media-element {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

#img {
  margin-top: 5px;
  inline-size: 100%;
  aspect-ratio: 16/9;

  object-fit: cover;
  margin-bottom: 5px;
  min-width: 70px;
}

.media-element > #img:hover {
  transition: transform 0.5s ease;
  transform: scale(1.08);
}

.folder-title {
  margin-top: 6px;
  padding-left: 15px;

  margin-bottom: 5px;
  min-width: 100px;
}

.big-image {
  max-width: 40%;
  max-height: 40%;
  margin: auto;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .scroll {
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    grid-auto-columns: 90% !important;

    overflow-x: auto;
    padding-bottom: 10px;
  }
  /* .card-image {
      width: 30% !important;
    } */
}

@media (min-width: 700px) and (max-width: 1024px) {
  .scroll {
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    grid-auto-columns: 45% !important;

    overflow-x: auto;
    padding-bottom: 10px;
  }
}
