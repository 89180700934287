.landing_page__main_container {
  background-color: #c9c9c9;
}
.landing_page__jumbotron {
  display: flex;
  justify-content: space-evenly;
}

.landing_page_large_devices_container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
}

.landing_page_listings {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: auto 0px;
  justify-content: center;
}

.landing_page_listing_title {
  margin-left: 3.5%;
  display: block;
}

.divider {
  width: 70%;
  margin: auto !important;
}
.card-scroller {
  display: none;
}

.landing_page_for_mobile_devices {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .landing_page_listing_title {
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .landing_page_for_mobile_devices {
    display: block !important;
  }
  .landing_page_large_devices_container {
    display: none !important;
  }
  .landing_page_card_scroller {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 90%;
    grid-gap: 10px !important;
    margin-left: 10px;
    overflow-x: auto;
  }
}
